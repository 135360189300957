<template>
  <div class="real3d" v-if="Object.keys(basicData).length != 0">
    <Carousel :list="basicData.basicImg" class="real3d_Carousel"> </Carousel>
    <ul class="real3d_table">
      <li v-for="(item, idx) in basicData.basicList" :key="idx">
        <div class="table_img">
          <img :src="basicData.basicList2[idx]" alt="" />
        </div>
        <div class="table_text">
          <h2>{{ item.key }}</h2>
          <h4>{{ item.value }}</h4>
        </div>
      </li>
    </ul>
    <div
      class="real3d_desgin"
      :style="{
        background: `url(${basicData.basicBg[0]}) 0 0/100% auto no-repeat`
      }"
    >
      <div class="all_title" style="padding-top: 140px">
        <h2>{{ basicData.basicTit[0].key }}</h2>
        <p>{{ basicData.basicTit[0].value }}</p>
      </div>
    </div>
    <div class="real3d_industry">
      <div class="all_title">
        <h2>{{ basicData.basicTit[1].key }}</h2>
        <p>{{ basicData.basicTit[1].value }}</p>
      </div>
      <ul class="real3d_industry_nav">
        <li v-for="(item, idx) in szhData.szhList" :key="idx">
          <video
            width="100%"
            height
            controls
            :poster="item.key"
            style="border-top-left-radius: 10px;border-top-right-radius: 10px;height: 200px;"
          >
            <source :src="item.value" height />
          </video>
          <div class="li_text">
            <h2>{{ szhData.szhList2[idx].key }}</h2>
            <p>{{ szhData.szhList2[idx].value }}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="real3d_sdk">
      <div class="all_title">
        <h2>{{ basicData.basicTit[2].key }}</h2>
        <p>{{ basicData.basicTit[2].value }}</p>
      </div>
      <div class="real3d_sdk_nav">
        <img :src="basicData.basicBg[1]" alt="" />
      </div>
    </div>
    <div class="real3d_case">
      <div class="all_title">
        <h2>{{ basicData.basicTit[3].key }}</h2>
        <p>{{ basicData.basicTit[3].value }}</p>
      </div>
      <ul class="real3d_case_nav">
        <li v-for="(item, idx) in alList" :key="idx">
          <div class="case_img zz_all_img">
            <img :src="item.site_images" alt="" style="width:100%" />
          </div>
          <h2>{{ item.title }}</h2>
          <p>{{ item.subtitle }}</p>
        </li>
        <a href="#/case3d" class="case_tips" @click="toLink(109)"
          >查看更多案例 >></a
        >
      </ul>
    </div>
    <div class="real3d_VR">
      <div class="all_title">
        <h2>{{ basicData.basicTit[4].key }}</h2>
        <p>{{ basicData.basicTit[4].value }}</p>
      </div>
      <div class="real3d_VR_nav">
        <img :src="basicData.basicBg[2]" alt="" />
      </div>
    </div>
    <div class="real3d_free">
      <div class="all_title">
        <h2>{{ basicData.basicTit[5].key }}</h2>
        <p>{{ basicData.basicTit[5].value }}</p>
      </div>
      <div class="real3d_free_nav">
        <img :src="basicData.basicBg[3]" alt="" />
      </div>
    </div>
    <div class="real3d_works">
      <div class="all_title">
        <h2>{{ basicData.basicTit[6].key }}</h2>
        <p>{{ basicData.basicTit[6].value }}</p>
      </div>
      <div class="real3d_works_type">
        <ul class="nav_col">
          <li
            v-for="(item, idx) in worksType"
            :key="idx"
            :class="[zp_xz == idx ? 'xz_li' : '']"
            @click="getList(item.id, idx, 1)"
          >
            {{ item.name }}
          </li>
        </ul>
        <div class="nav_sort">
          <span style="color: #999;">排序方式</span>
          <i
            class="el-icon-view"
            :style="{ color: active == 0 ? '#1342ff' : '' }"
            @click="active = 0"
          ></i>
          <i
            class="el-icon-time"
            :style="{ color: active == 1 ? '#1342ff' : '' }"
            @click="active = 1"
          ></i>
        </div>
      </div>
      <ul class="real3d_works_list">
        <li v-for="(item, idx) in worksList" :key="idx">
          <div class="li_img zz_all_img">
            <img :src="item.site_images" alt="" />
          </div>
          <div class="li_text">
            <h2>{{ item.title }}</h2>
            <p>{{ item.subtitle }}</p>
          </div>
        </li>
      </ul>
      <div class="real3d_works_btn centerText" @click="loadData()">
        <span
          :style="{
            background: `url(${basicData.basicTit[7].value}) 0 0 / cover`
          }"
        ></span>
        {{ basicData.basicTit[7].key }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  getBasicMsg,
  getPageMsg,
  getZzCaseList,
  getAllList,
  getProductTypes
} from '@/api/api'
import Carousel from '@/common/Carousel/zzCarousel.vue'
export default {
  components: { Carousel },
  data () {
    return {
      basicData: {},
      szhData: {},
      worksType: [],
      zp_xz: 0,
      active: 0,
      worksList: [],
      total: 0,
      form: {
        page: 1,
        pagesize: 20,
        type_id: '',
        project: ''
      },
      alList: []
    }
  },
  methods: {
    getData () {
      let that = this
      getBasicMsg(this.changeData()).then(item => {
        if (item.data.code == 1) {
          let id = item.data.data.menu_id
          that.form.project = item.data.data.project_id
          that.getList('', 0)
          getPageMsg(id).then(res => {
            if (res.data.code == 1) {
              that.basicData = res.data.data.basic
              that.szhData = res.data.data.数字化
            }
          })
          getProductTypes(that.form.project).then(res => {
            if (res.data.code == 1) {
              res.data.data.unshift({
                name: '全部',
                id: ''
              })
              that.worksType = res.data.data
            }
          })
          let obj = {
            page: 1,
            pagesize: 6,
            type_id: '',
            project: that.form.project
          }
          getZzCaseList(obj).then(res => {
            if (res.data.code == 1) {
              that.alList = res.data.data.list
            }
          })
        }
      })
    },
    getList (i, x, d) {
      if (d) {
        this.form.page = d
      }
      this.zp_xz = x
      this.form.type_id = i
      getAllList(this.form).then(res => {
        if (res.data.code == 1) {
          this.worksList = res.data.data.list
          this.total = res.data.data.total
        }
      })
    },
    loadData () {
      if (this.total > this.form.pagesize * this.form.page) {
        this.form.page += 1
        getAllList(this.form).then(res => {
          if (res.data.code == 1) {
            this.worksList = this.worksList.concat(res.data.data.list)
          }
        })
      } else {
        this.$message.warning('已加载所有的模型。')
      }
    },
    toLink (i) {
      sessionStorage.setItem(this.changeData() + 'id', i)
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
.real3d {
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #212529;
  /deep/.real3d_Carousel {
    overflow: hidden;
    img {
      line-height: 0;
      max-width: 100%;
      position: relative;
      animation: scaleAnim 3s ease forwards;
    }
    .thumbs {
      display: none;
    }
  }
  .all_title {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
    h2 {
      font-size: 28px;
      color: #304189;
      text-align: center;
      font-weight: 700;
      letter-spacing: 0;
    }
    p {
      margin-top: 15px;
      font-size: 15px;
      color: #596bb6;
    }
  }
  .real3d_table {
    display: flex;
    flex-wrap: wrap;
    width: 1200px;
    justify-content: space-between;
    margin: -40px auto 0;
    li {
      width: 280px;
      height: 100px;
      border-radius: 10px;
      opacity: 1;
      background: #fff;
      box-shadow: 0 4px 10px 0 rgba(202, 215, 233, 0.25);
      z-index: 0;
      display: flex;
      align-items: center;
      .table_img {
        margin-left: 35px;
        width: 100px;
        height: 100%;
        img {
          width: 100%;
          height: auto;
          max-height: 100%;
        }
      }
      .table_text {
        margin-top: -10px;
        h2 {
          font-size: 18px;
          color: #333;
        }
        h4 {
          margin-top: 10px;
          font-size: 14px;
          color: #999;
        }
      }
    }
  }
  .real3d_desgin {
    margin-top: -100px;
    width: 100%;
    padding-bottom: 30%;
  }
  .real3d_industry,
  .real3d_case,
  .real3d_free {
    background: linear-gradient(0deg, #1349ff, #1349ff), #f3f5ff;
    padding: 80px 0;
    .all_title {
      h2 {
        color: #fff;
      }
      p {
        color: #b6c4ff;
      }
    }
    .real3d_industry_nav {
      width: 1300px;
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      margin-top: 50px;
      li {
        width: calc(25% - 15px);
        background-color: #fff;
        border-radius: 10px;
        margin-left: 20px;
        h2 {
          margin-bottom: 10px;
          font-weight: 600;
          color: #333;
        }
        .li_text {
          color: #666;
          font-size: 12px;
          line-height: 1.5;
          padding: 15px 20px;
          h2 {
            font-weight: bold;
            font-size: 18px;
          }
          p {
            color: #333;
          }
        }
      }
      li:nth-child(1) {
        margin-left: 0;
      }
    }
  }
  .real3d_sdk,
  .real3d_VR {
    background: #f0f3ff;
    padding: 80px 0;
    text-align: center;
  }
  .real3d_sdk .real3d_sdk_nav {
    width: 60%;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .real3d_case .real3d_case_nav {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    color: #fff;
    margin-top: -20px;
    li {
      width: calc(33.33% - 20px);
      margin: 0 10px;
      margin-top: 20px;
      .case_img {
        border-radius: 8px;
        box-shadow: 0px 0px 8px rgba(169, 169, 169, 1);
        overflow: hidden;
      }
      h2 {
        margin: 15px 0;
        font-weight: 600;
      }
      p {
        line-height: 1.5;
        font-size: 12px;
        text-indent: 2em;
      }
    }
    .case_tips {
      display: block;
      text-align: center;
      margin: 0 auto;
      margin-top: 50px;
      font-size: 14px;
    }
  }
  .real3d_VR .real3d_VR_nav {
    img {
      width: 50%;
      height: auto;
      display: inline-block;
      box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
      border: 8px solid #969696;
      border-radius: 20px;
    }
  }
  .real3d_free .real3d_free_nav {
    text-align: center;
    img {
      width: 50%;
      height: auto;
      display: inline-block;
      background-color: #fff;
      border-radius: 20px;
      padding: 10px;
    }
  }
  .real3d_works {
    padding: 80px 0;
    width: 80%;
    margin: 0 auto;
    .real3d_works_type {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;
      font-size: 14px;
      .nav_col {
        flex: 1;
        overflow: auto;
        display: flex;
        li {
          min-width: 80px;
          padding: 14px 0;
          margin-left: 15px;
          text-align: center;
          border-radius: 20px;
          color: #1342ff;
          transition: all 0.3s ease-out;
          cursor: pointer;
        }
        li:nth-child(1) {
          margin-left: 0;
        }
        .xz_li {
          color: #fff;
          background-color: #1342ff;
        }
      }
      .nav_sort {
        display: flex;
        align-items: center;
        i {
          margin-left: 15px;
          font-size: 18px;
          cursor: pointer;
        }
      }
    }
    .real3d_works_list {
      margin-left: -10px;
      margin-right: -10px;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      li {
        padding: 0 10px;
        margin-bottom: 20px;
        transition: all 0.2s ease-in;
        width: calc(20% - 20px);
        .li_img {
          width: 100%;
          height: calc(100% - 55px);
          background-color: #eeeff4;
          border-radius: 15px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .li_text {
          height: 40px;
          padding: 0 4%;
          margin-top: 15px;
          h2 {
            font-size: 14px;
            color: #444;
            font-weight: 600;
          }
          p {
            font-size: 12px;
            color: #999;
            margin-top: 10px;
          }
        }
      }
    }
    .real3d_works_btn {
      width: 110px;
      height: 36px;
      border-radius: 80px;
      opacity: 1;
      background: #fff;
      box-sizing: border-box;
      border: 1px solid #c7d2ff;
      margin: 0 auto;
      margin-top: 30px;
      font-size: 12px;
      cursor: pointer;
      color: #1342ff;
      span {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-left: -10px;
      }
    }
  }
}
</style>
